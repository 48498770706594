<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      spinner="bar-fade-scale"
      color="#1976d2"
      size="128"
      is-full-screen
    />
    <v-layout v-if="ServerError" wrap justify-center>
      <v-flex px-5 pt-5 xs12 sm12 md12 lg12 xl12 text-center>
        <ServerError />
      </v-flex>
    </v-layout>
    <v-layout v-else wrap justify-center>
      <v-flex px-5 pt-5 xs12 sm12 md12 lg12 xl12 text-center>
        <v-card>
          <v-layout wrap justify-start pb-5>
            <v-flex text-left xs12 sm12 md6 px-3
              ><span style="font-size: 22px">Pending Appointments</span>
            </v-flex>
            <v-spacer></v-spacer>
            <v-flex xs12 sm3 md2>
              <v-select
                dense
                :items="items"
                v-model="booktype"
                item-text="name"
                item-value="value"
                label="Booking Type"
                outlined
                clearable
              ></v-select>
            </v-flex>
          </v-layout>
          <v-layout wrap justify-start pb-5 v-if="user.length > 0">
            <v-flex xs12>
              <v-simple-table dense>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-center">Name</th>
                      <th class="text-center">Number</th>
                      <th class="text-center">Occasion</th>
                      <th class="text-center">Occasion Date</th>
                      <th class="text-center">Booking Type</th>
                      <th class="text-center">Tag</th>
                      <th class="text-center">Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, i) in user" :key="i">
                      <td>{{ item.name }}</td>
                      <td>{{ item.number }}</td>
                      <td>{{ item.occasion }}</td>
                      <td><span v-if="item.occasiondate">{{ item.occasiondate.slice(0,10) }}</span></td>
                      <td> <span v-if="item.booktype=='OmniStore'">Omni Store</span>
                        <span v-if="item.booktype!='OmniStore'">Style Partner</span></td>
                      <td v-if="item.keyword">
                        <span v-for="(key, k) in item.keyword" :key="k">
                        {{ key.keyword }} <span v-if="k < item.keyword.length - 1">, </span>
                      </span></td>
                      <td>
                        <v-layout wrap justify-center>
                          <v-flex xs12 align-self-center lg9 pt-6>
                            <!-- :disabled="item.verificationStatus == 'Verified'" -->
                            <v-select
                              v-model="item.status"
                              :items="itemStatus"
                              item-text="name"
                              item-value="value"
                              item-color="#8d8d8d"
                              color="#8d8d8d"
                              outlined
                              dense
                              label="Status"
                              @change="askStatus(item._id)"
                            >
                            </v-select>
                          </v-flex>
                        </v-layout>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
              <div class="pt-2" v-if="pages > 1">
                <v-pagination
                  :length="pages"
                  :total-visible="7"
                  v-model="currentPage"
                  color="#FF0000"
                ></v-pagination>
              </div>
            </v-flex>
          </v-layout>
          <v-layout v-else pt-15 justify-center fill-height wrap>
            <v-flex text-center xs12 lg12>
              <span style="font-size: 22px; letter-spacing: 1px">
                No Data Found...
              </span>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
    <v-dialog persistent v-model="updateDialog" max-width="600px">
      <v-card>
        <v-card-title style="font-size: 16px"
          >Are you sure you want to change current Status?</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="getData(), (updateDialog = false)"
            >Cancel</v-btn
          >
          <v-btn color="blue darken-1" text @click="updateStatus(dialogId)"
            >OK</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
  <script>
import axios from "axios";
export default {
  data() {
    return {
      appLoading: false,
      search: "",
      ServerError: false,
      page: 1,
      currentPage: 1,
      pages: 0,
      limit: 12,
      itemStatus: ["pending", "active"],
      items:[{name:"All", value:""},{name:"Omni Store", value:"OmniStore"}, {name:"Style Partner", value:"StylePartner"}],
      booktype:"",
      user: [],
      updateDialog: false,
      dialogId: null,
      dialogStatus: null,
    };
  },
  mounted() {
    this.getData();
  },
  watch: {
    currentPage() {
      this.getData();
    },
    booktype(){
      this.getData();
    }
  },
  methods: {
    getData() {
      this.appLoading = true;
      axios({
        url: "/get/stylepartner/appointment/pending",
        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          limit: this.limit,
          page: this.currentPage,
          filter:this.booktype
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.user = response.data.data;
          this.pages = Math.ceil(response.data.totalLength / this.limit);
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    askStatus(id) {
      this.updateDialog = true;
      this.dialogId = id;
    },
    updateStatus(id) {
      this.updateDialog = false;
      axios({
        url: "/stylepartner/update/appointment/status",
        method: "POST",
        data: { id: id, status: true },
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status) {
            // this.appLoading = false;
            this.dialogId = null;
            this.dialogStatus = null;
            this.msg = "Status updated successfully";
            this.showSnackBar = true;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.getData();
          }
        })
        .catch((err) => {
          // this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>
  
  <style>
</style>